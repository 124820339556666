import type { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import { Layout, Separator } from '@components/index'

import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'

import { FaEye } from '@react-icons/all-files/fa/FaEye'
import { FaArrowAltCircleRight } from '@react-icons/all-files/fa/FaArrowAltCircleRight'
import { useAuth } from '../stores/authContext'

import { AuthErrorCodes, fetchSignInMethodsForEmail } from 'firebase/auth'
import { getFirebaseAuth } from '../lib/firebase'
import { useRouter } from 'next/router'
import { checkEmailV1, loginWithPasswordV1 } from '../data/Auth'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { segment } from 'src/lib/analytics'
import useLoading from 'src/hooks/useLoading'
import ACTIONS from '@redux/constants'

const auth = getFirebaseAuth()

enum EmailMode {
  SIGN_UP = 'signup',
  SIGN_IN = 'signin',
  SIGN_IN_V1 = 'signinv1',
}

const signInLabel = 'Sign in'
const signUpLabel = 'Sign up to openigloo as an agent, broker or landlord'

const Home: NextPage = () => {
  const router = useRouter()
  const { signUp, loginWithPopup, loginWithEmailAndPassword, sendPasswordReset, authReady } =
    useAuth()

  const { token, userManagedBuildings } = useAppSelector((state) => state.currentUser)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [mode, setMode] = useState<EmailMode | null>(null)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [passwordMasked, setPasswordMasked] = useState(true)
  const [nextPath, setNextPath] = useState('/home')

  const { isFetching } = useLoading([ACTIONS.USER.GET_BUILDINGS])

  useEffect(() => {
    if (!token) return
    // @ts-ignore
    if (isFetching) return
    if (!(userManagedBuildings && userManagedBuildings.length > 0)) {
      router.push('/onboarding')
      return
    } else {
      if (nextPath) {
        router.push(nextPath)
      } else {
        router.push('/home')
      }
    }
  }, [userManagedBuildings, isFetching])

  async function onLoginViaGoogle() {
    await loginWithPopup()
    setIsAuthenticating(false)
    setErrorMsg(null)
    // await onLoginSuccess(token)
  }

  async function signup() {
    if (email && password) {
      setIsAuthenticating(true)
      try {
        await signUp(email, password)
        setErrorMsg(null)
      } catch (err: any) {
        if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
          await signin()
        } else if (err.code === AuthErrorCodes.REJECTED_CREDENTIAL) {
          setErrorMsg('Failed to signup, please try again!')
        }
      }
      setIsAuthenticating(false)
    } else {
      setErrorMsg('Passwords do not match!')
      setIsAuthenticating(false)
    }
  }

  async function signin() {
    if (email && password) {
      try {
        setIsAuthenticating(true)
        await loginWithEmailAndPassword(email, password)
        setIsAuthenticating(false)
        setErrorMsg(null)
      } catch (err) {
        setErrorMsg('Failed to signin please try again!')
      }
    } else {
      setErrorMsg('Enter your email and password!')
    }
    setIsAuthenticating(false)
  }

  const submitEmailAuthForm = async () => {
    try {
      if (mode === EmailMode.SIGN_UP) {
        await signup()
      } else {
        if (mode === EmailMode.SIGN_IN_V1) {
          const loginSuccess = await loginWithPasswordV1(email, password)
          if (loginSuccess) {
            await signup() //Create User in V2 from v1
          } else {
            const loginError = new Error('auth/wrong password')
            // @ts-expect-error: TS23392
            loginError.code = 'auth/wrong password'
            throw loginError
          }
        } else {
          await signin()
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  // clickHandlers or eventHandlers
  const onSubmitCred = (e) => {
    e.preventDefault()
    if (password.length > 6) {
      submitEmailAuthForm()
    } else {
      setErrorMsg('The password minimum length is 6')
    }
  }

  const onResetPress = () => {
    if (mode === EmailMode.SIGN_IN_V1) {
      router.push('/reset-password')
    } else {
      sendPasswordReset(email)
      setErrorMsg('Please check your email for instructions to reset your password')
    }
  }

  useEffect(() => {
    if (isAuthenticating && mode === 'signup') signup()
    if (isAuthenticating && mode === 'signin') signin()
  }, [isAuthenticating, mode])

  useEffect(() => {
    const { next } = router.query
    if (next) {
      setNextPath(next.toString())
    }
  }, [router.query])

  const handleEmailSubmit = async (e) => {
    e.preventDefault()
    if (!isEmail(email)) {
      return
    }
    const v = email
    const isValid = isEmail(v)
    if (isEmpty(v) || !isValid) setMode(null)

    if (isEmail(v)) {
      const results = await fetchSignInMethodsForEmail(auth, v)
      let isEmailExistsV2 = results.length > 0
      let isEmailExistsV1 = false
      if (!isEmailExistsV2) {
        isEmailExistsV1 = await checkEmailV1(v)
      }
      let hasLoggedInViaEmailV2 = results.indexOf('password') > -1
      if (isEmailExistsV2 || isEmailExistsV1) {
        if (hasLoggedInViaEmailV2) {
          setMode(EmailMode.SIGN_IN)
        } else if (isEmailExistsV1) {
          setMode(EmailMode.SIGN_IN_V1)
        } else {
          window.alert(`Email exists! Please log in via: ${results.join(', ')}`)
        }
      } else {
        setMode(EmailMode.SIGN_UP)
      }
    }
  }

  return (
    <>
      <Layout showLoginButtons={false}>
        <div className="min-h-screen-70">
          <main className="ext-gray-600 font-body">
            <div className="pt-40">
              <div className="flex flex-col px-2 w-full sm:w-10/12 md:w-1/2 lg:w-1/3 mx-auto pb-4">
                <span className="text-xs sm:text-sm text-center">
                  Log in or sign up to claim your buildings.
                </span>

                <div
                  className="relative flex flex-row p-3 justify-center rounded-lg border border-gray-900 mx-auto cursor-pointer w-full mt-4"
                  onClick={onLoginViaGoogle}
                >
                  <div className="absolute left-4 flex flex-col justify-center">
                    <Image alt="Google Login" src="/icons/google-logo.svg" width={24} height={24} />
                  </div>
                  <span className="text-black text-base text-center">Continue with Google</span>
                </div>

                <div className="flex my-8 flex-row items-center justify-evenly">
                  <Separator customStyle="w-1/4 bg-gray-400" />
                  <span className="mx-2 text-xs sm:text-sm text-gray-400">
                    or continue with email
                  </span>
                  <Separator customStyle="w-1/4 bg-gray-400" />
                </div>

                <form
                  className="flex flex-row items-center justify-start w-full rounded-lg border border-gray-400 mb-4"
                  onSubmit={(e) => handleEmailSubmit(e)}
                >
                  <input
                    autoComplete="on"
                    className="border-none px-2 py-1 m-2 bg-transparent w-full"
                    placeholder="Your email"
                    value={email}
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="mr-4 cursor-pointer" type="submit">
                    <FaArrowAltCircleRight size="20" />
                  </button>
                </form>
                {mode !== null && (
                  <form onSubmit={onSubmitCred}>
                    <div className="flex flex-row items-center justify-start w-full rounded-lg border border-gray-400 mb-4">
                      <input
                        className="border-none px-2 py-1 m-2 bg-transparent w-full"
                        placeholder={
                          mode == EmailMode.SIGN_UP ? 'Set your password' : 'Enter your password'
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={passwordMasked ? 'password' : 'text'}
                        autoFocus
                      />
                      <FaEye
                        onClick={() => setPasswordMasked(!passwordMasked)}
                        size="20"
                        className="mr-4"
                      />
                    </div>

                    <div className="self-center flex-wrap w-full mb-4">
                      <span className="text-sm font-normal">
                        By selecting sign up/in, Continue with Google or Continue with Apple, you
                        agree to our{' '}
                        <Link passHref={true} href="/listings-quality-policy">
                          <a className="text-blue-300 cursor-pointer font-semibold">
                            Listings Quality Policy
                          </a>
                        </Link>
                        {', '}
                        <Link passHref={true} href="https://www.openigloo.com/terms-of-use">
                          <a className="text-blue-300 cursor-pointer font-semibold">
                            Terms and Conditions
                          </a>
                        </Link>
                        {', '}
                        and{' '}
                        <Link passHref={true} href="https://www.openigloo.com/privacy-policy">
                          <a className="text-blue-300 cursor-pointer font-semibold">
                            Privacy Statement
                          </a>
                        </Link>{' '}
                      </span>
                    </div>

                    {errorMsg && (
                      <div className="w-full text-sm text-red-600 mb-4">
                        <span>{errorMsg}</span>
                      </div>
                    )}
                    <div className="w-full flex items-center">
                      <button
                        type="submit"
                        disabled={isAuthenticating}
                        className="bg-lightred-300 px-3 mb-4 text-white py-2 rounded-3xl text-center m-1 mx-auto"
                      >
                        {isAuthenticating
                          ? 'Loading...'
                          : mode === EmailMode.SIGN_UP
                          ? signUpLabel
                          : signInLabel}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </main>
        </div>
      </Layout>
    </>
  )
}

export default Home
